<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/electricMeterInfo">电表管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="所属终端" prop="terminalId">
        <el-select
            v-model="queryModel.terminalId"
            filterable
            remote
            placeholder
            size="mini"
            :remote-method="queryTerminal"
            style="width:300px"
          >
            <el-option
              v-for="terminal in queryTerminalResult"
              :key="terminal.id"
              :label="terminal.remark"
              :value="terminal.id"
            ></el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" sort-by="name_" label="电表名称" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="meterNo" sort-by="meter_no" label="表号" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="unitNo" sort-by="unit_no" label="单位号" sortable="custom" width="180"></el-table-column>
      <el-table-column
        prop="terminalRemark"
        sort-by="terminalRemark"
        label="所属终端"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <electricMeterInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></electricMeterInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import ElectricMeterInfoDetail from "./electricMeterInfo-detail";
import electricMeterInfoApi from "@/api/base/electricMeterInfo";
import terminalApi from "@/api/base/terminalInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        meterNo: "",
        unitNo: "",
        name: "",
        terminalId: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      queryTerminalResult: []
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("meterNo", self.queryModel.meterNo);
      formData.append("unitNo", self.queryModel.unitNo);
      formData.append("name", self.queryModel.name);
      formData.append("terminalId", self.queryModel.terminalId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      electricMeterInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    queryTerminal(keywords) {
      var formData = new FormData();
      formData.append("keywords", keywords);
      formData.append("limit", 10);

      return terminalApi.query(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.queryTerminalResult = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          electricMeterInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        electricMeterInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
    this.queryTerminal("");
  },
  components: {
    "electricMeterInfo-detail": ElectricMeterInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>